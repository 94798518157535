import { COMPANY_USER_ROLE_FULL_FRAGMENT } from '@/graphql/_Fragments/CompanyUserRole/Full';
import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const EXHIBITOR_ACTION_BUTTON_CREATEMEETING_FRAGMENT = `
  fragment exhibitorActionButtonCreatemeetingFragment on Exhibitor {
    typename: __typename
    uid
    name
    logoFileResource {
      ...fileResourceBaseFragment
    }
    userRoles {
      ...companyUserRoleFullFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${COMPANY_USER_ROLE_FULL_FRAGMENT}
`;
